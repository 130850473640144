<template>
  <div id="kontakt" class="mx-auto mt-12 max-w-screen-md bg-opacity-0 px-5 py-8 lg:py-10 xl:px-0">
    <Component :is="'script'" v-if="loading === false">
      hbspt.forms.create({ region: "eu1", portalId: "26789555", formId: "{{ data.formId }}" });
    </Component>
  </div>
</template>

<script setup lang="ts">
import { useScriptTag } from "@vueuse/core";

interface B2BHubspotFormData {
  formId: string;
}

defineProps<{
  data: B2BHubspotFormData;
}>();

const loading = ref(true);

const { load } = useScriptTag("//js-eu1.hsforms.net/forms/embed/v2.js");

load().finally(() => {
  loading.value = false;
});
</script>

<style scoped></style>
